import React from "react";
import siteLogo from "../assets/borderless.png";
import styles from "./header.module.css";

function Header() {
  return (
    <div className={styles.containerNav}>
      <div className={`${styles.mainContainer}`}>
        <nav
          className={`navbar navbar-expand-lg navbar-light ${styles.navbar} `}
        >
          <div className={styles.navbarImage}>
            <a href="">
              <img src={siteLogo} height="38px" href="#" alt="Site-Logo"></img>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
