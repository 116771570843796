import React, { useEffect, useState } from "react";
import "../App.css";
import email from "../assets/email-svgrepo-com.svg";
import phone from "../assets/phone-svgrepo-com.svg";
import location from "../assets/location-pin-svgrepo-com.svg";

function ContactSection() {
  const [selectedInterest, setSelectedInterest] = useState(['Global Investing']);
  const [emailError, setEmailError] = useState(""); 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const interests = [
    "Global Investing",
    "Cross-border Payments",
    "Multi-currency Banking"
  ];

  // Handle pill selection
  const handleInterestClick = (interest) => {
    let updatedInterests = [...selectedInterest]; 
    if(updatedInterests.includes(interest)){
      if (updatedInterests.length > 1) {
        updatedInterests = updatedInterests.filter(item => item !== interest);
      } else {
        console.log("At least one interest must be selected."); // Prevent removing the last interest
      }
    }else{
      updatedInterests.push(interest); 
    }
      setSelectedInterest(updatedInterests);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setEmailError(""); // Clear any previous error
      return true;
    } else {
      setEmailError("Please enter a valid email address.");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      return;
    }
    setIsSubmitting(true); 
    let data = {
      "category": "custom.socket",
      "type": "custom.socket",
      "receivers": "",
      "data": {
        "Customer_Email": formData.email,
        "toEmail": "sitashwa@stockal.com",
        "ccEmail": "nishant@stockal.com",
        "subject": "borderless world platform form submission",
        "type": "custom.socket",
        "Customer_Name": formData.name,
        "Comments": formData.message,
        "ProductName": selectedInterest
      },
      "notificationType": [
        {
          "type": "email"
        }
      ],
      "isCustom": true
    };

    try {
      const response = await fetch(
        "https://uatapi-v2.stockal.com/v2/notification/sendNotification-custom",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "stockal-secret-key": "SDsdvS2cZwo60ZHsdvjnDFBsbD8gwviaF1FfECbRAd3m98hbsJHnOq"
          },
          body: JSON.stringify(data)
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setSubmissionSuccess(true); // Indicate success
        console.log("Form submitted successfully!", responseData);
      } else {
        console.error("Error in form submission", response.status);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setIsSubmitting(false); 
    }
  };

  useEffect(() => {
    if (submissionSuccess) {
      const timer = setTimeout(() => {
        setFormData({
          name: "",
          email: "",
          message: ""
        });
        setSelectedInterest("Global Investing");
        setEmailError("");
        setSubmissionSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [submissionSuccess]);

  return (
    <div className="contact-container">
      {/* Left side with text and contact details */}
      <div className="contact-left">
        <h2>
          Let’s talk on something <span className="highlight">great</span> together
        </h2>
        <p className="contact-details" style={{ marginTop: "50px" }}>
          <a
            href="mailto:business@borderless.world"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
              textDecoration: "none",
              color: "black"
            }}
          >
            <img
              src={email}
              style={{ height: "30px", width: "30px", marginRight: "20px" }}
              alt=""
            />
            <span>business@borderless.world</span>
          </a>
          <a
            href="tel:+13239099080"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
              textDecoration: "none",
              color: "black"
            }}
          >
            <img
              src={phone}
              style={{ height: "30px", width: "30px", marginRight: "20px" }}
              alt=""
            />
            <span>+1 323 909 9080</span>
          </a>
          <a style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
            <img
              src={location}
              style={{ height: "30px", width: "30px", marginRight: "20px" }}
              alt=""
            />
            <span>New York | Dubai | Bangalore</span>
          </a>
        </p>
      </div>

      {/* Right side with pills, inputs, and button */}
      <div className="contact-right">
        <h3>I’m Interested in:</h3>
        <div className="interest-pills">
        {interests.map((interest, index) => (
          <div
            key={index}
            className={`pill ${selectedInterest.includes(interest) ? "selected" : ""}`}  // Check if interest exists in the array
            onClick={() => handleInterestClick(interest)}
          >
            {interest}
          </div>
        ))}
        </div>

        {/* Form inputs for name, email, and message */}
        <div className="contact-form">
          <div className="input-container" style={{ marginTop: "10px" }}>
            <label className={formData.name ? "float-label" : ""} htmlFor="name">
              Your name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="form-input"
            />
          </div>

          <div className="input-container">
            <label className={formData.email ? "float-label" : ""} htmlFor="email">
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="form-input"
            />
          </div>

          <div className="input-container">
            <label className={formData.message ? "float-label" : ""} htmlFor="message">
              Your message (Please share more details)
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className="form-textarea"
            ></textarea>
          </div>

          {emailError && (
            <span className="error-message" style={{ color: "red" }}>
              {emailError}
            </span>
          )}

          {/* Submit button */}
          <button className="submit-button" onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send message"}
          </button>
        </div>

        {/* Confirmation message */}
        {submissionSuccess && (
          <p className="success-message" style={{ color: "green" }}>
            Thank you! Our team will contact you soon.
          </p>
        )}
      </div>
    </div>
  );
}

export default ContactSection;
