import React from "react";
import styles from "./header.module.css";

function Footer() {
  return (
    <div className={`${styles.mainContainerfooter}`}>
      <p>
        Borderless Softtech Pvt Ltd ("BSPL") licenses and offers the Stockal
        Platform to subscribers and users who are based in India and wish to
        make global transactions. Such subscribers and users may be offered
        annual or monthly subscription plans by BSPL and in such cases, BSPL
        acts as their service provider.
      </p>

      <p>
        Borderless Investing Inc., (“Borderless Investing”), the owner of the
        Stockal Platform, is not a broker-dealer nor an investment advisor and
        through its affiliate stockbroker Stockal Securities Pvt. Ltd., (SEBI
        INZ000301236), acting as the introducing entity has entered into a
        clearing agreement with DriveWealth LLC., to offer U.S. equities on the
        Stockal Platform. The Stockal Platform enables investments in a diverse
        set of global assets from a single account from anywhere in the world.
        As such, Borderless Investing makes no warranties or representations,
        express or implied, on products and services offered through its
        website/platform. It accepts no liability for any damages or losses,
        however, caused in connection with the use of related services.
      </p>
      <p>
        All investments involve risk and returns will fluctuate, are subject to
        market volatility and may cause losses. The material on this
        website/platform is provided solely for informational and educational
        purposes and shall not in any manner be considered a recommendation or
        endorsement of any strategy or investment, nor an offer to sell or a
        solicitation of an offer to buy securities, products or services nor
        advice. Borderless Investing makes no guarantee as to the currency,
        accuracy, or quality of information published and/or archived on this
        website/platform, nor will Borderless Investing accept any
        responsibility for other organizations, businesses, and private persons
        that provide information on this website/platform. All information on
        this website/platform regarding products and services provided by
        Borderless Investing is subject to change without notice. Reasonable
        efforts are taken to ensure the accuracy and integrity of all
        information provided here, but Borderless Investing is not responsible
        for misprints, out of date information, or errors. Any investment
        decision and/or strategy that investors make or employ, whether or not
        such decision or strategy derives from or relies upon material accessed
        or provided through this website/platform, is undertaken at the
        investor’s sole discretion and risk. Before making any investment
        decisions, investors should consult additional sources of information
        and/or their legal or tax advisor. PLEASE READ THIS FULL DISCLOSURE
        CAREFULLY BEFORE USING THIS SERVICE. BY USING THE SERVICE OR CLICKING
        AGREE YOU ARE AGREEING TO BE BOUND BY THESE DISCLOSURES AND ALL TERMS
        AND CONDITIONS ON THIS WEBSITE/PLATFORM.
      </p>
      <p>
        *DriveWealth LLC., a member of the Securities Investor Protection
        Corporation (SIPC), will serve as the custodian for your securities
        account. In the event that DriveWealth LLC., fails and is placed in
        liquidation under the Securities Investor Protection Act, securities in
        your brokerage account may be protected up to $500,000. For details,
        please see www.sipc.org.
      </p>
      <p>
        All brands and logos used on this website are the property of their
        respective owners.
      </p>
    </div>
  );
}

export default Footer;
