import React, { useState } from "react";
import "./App.css"; // Import CSS file for styling
import ContactSection from "./component/ContactSection";
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
import download from "./assets/download-icon.png"
import Header from "./component/Header";
import Footer from "./component/Footer";
import LinkImage from './assets/linkImage.png';

const pdfFiles = [
  {
    name: "Borderless Investing",
    url: '/borderlessinvesting.pdf',
    image: "/investing.jpg",
    link: "https://stockal.com/",
    text : "Stockal"
  },
  {
    name: "Borderless Banking",
    url: '/borderlessbanking.pdf',
    image: "/banking.jpg",
    text: ""
  },
  {
    name: "Borderless Remittance",
    url: '/borderlessremitance.pdf',
    image: "/remittance.jpg",
    link: "https://borderless.pe/",
    text : "BorderlessPe"
  }
];

function PdfCard({ name, url, image, onImageClick,text,link}) {
  return (
    <div>
      <div className="card">
        <img
          src={image}
          alt={name}
          className="pdf-image"
          onClick={() => onImageClick(url)}
        />
        <a href={url} download={name} className="download-link">
        <p className="pdf-name">{name}</p>
        <img src={download} style={{height: "20px", width: "20px"}} alt=""></img>
        </a>
      </div>
      <a>
        {text !== "" ?
        <p className="pdf-name" style={{fontSize:"14px",textAlign:"center"}}>
          {text !== "" ? `Know more at `: ""}
          <span className="link-text" onClick={() => window.open(link)} style={{fontSize:"15px"}}>{text}
            <img style={{width:"12px",marginLeft:"4px"}} src={LinkImage}></img></span>
        </p> : ""}
      </a>
    </div>
  );
}

function App() {

  const handleImageClick = (url) => {
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert('Failed to open the PDF. Please disable your popup blocker.');
    }
  };

  return (
    <>
    <Header />
    <div className="container">
      {pdfFiles.map((file, index) => (
        <PdfCard
          key={index}
          name={file.name}
          url={file.url}
          image={file.image}
          onImageClick={handleImageClick}
          text={file.text}
          link={file.link}
        />
      ))}

      <ContactSection />
    </div>
    <Footer />
    </>
  );
}

export default App;
